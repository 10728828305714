import Swal from "sweetalert2";

const SwitchAlert = (tit = "", msg = "", type = "success", cancel = "موافق") => {
    //   alertMsg = ('title','msg','type','cancel-btn')
 
    const alert = Swal.fire({
      text: msg,
      icon: type,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: "#317750",
      cancelButtonText: cancel,
    });
 
  return (alert);
}

export default SwitchAlert