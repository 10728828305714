const ButtonLog = (click,btnText,lodingtext,loadingtrue,disabled) => {
  return (
    <>
                <button
               onClick={click}
              className="btn btn-primary btn-lg btn-block w-100"
              disabled={disabled}
            >
              {loadingtrue ? lodingtext : btnText}
              {loadingtrue ? (
                <span
                  className="spinner-border me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
            </button>
    </>
  )
}

export default ButtonLog