import React from 'react';

const Switches = ({ onChange, disabled, checked }) => {
  const handleInputChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <label className="switch switch-sm" style={{paddingLeft:"20px"}}>
      <input
        type="checkbox"
        className="switch-input"
        onChange={handleInputChange}
        disabled={disabled} // تعطيل الـ checkbox بناءً على الشرط
        checked={checked} // تعيين حالة الـ checkbox بناءً على ما إذا كان الخيار محدداً
      />
      <span className="switch-toggle-slider">
        <span className="switch-on">
          <i className="bx bx-check"></i>
        </span>
        <span className="switch-off">
          <i className="bx bx-x"></i>
        </span>
      </span>
    </label>
  );
};

export default Switches;
