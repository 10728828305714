import React, { useEffect, useState } from "react";
import { url } from "../../axiosInstance";

const Avatar = () => {
  const [inf, setInf] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const parsData = JSON.parse(userData);
      setInf(parsData);

      console.log(inf.name);
    }
  }, []);
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="avatar-wrapper ">
        <div className="avatar avatar-md flex-shrink-0 me-3">
          <span className="avatar-initial rounded-circle bg-label-success">
            <i className="bx bx-user fs-1"></i>
          </span>
        </div>
      </div>
      <div className="text-center mt-1">
        <div className="text-heading fw-medium">
          {inf.name ? inf.name : "لا يوجد اسم"}
        </div>
        <small className="text-body">{inf.name ? inf.mobile : "-"}</small>
      </div>
    </div>
  );
};

export default Avatar;
