import React, { useState } from "react";
import axiosInstance from "../axiosInstance";
import SwitchAlert from "./SwitchAlert";
import ButtonLog from "./ButtonLog";
import { useNavigate } from "react-router-dom";

const Login = () => {

  const [loading, setLoading] = useState(false);
  let [idnational, setIdnational] = useState("");
  const [btndis,setBtndis] = useState("disabled");
  const navigate = useNavigate();
  const handleIdNationalChange = (e) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/\D/g, ""); // إزالة الأحرف غير الرقمية
    if (sanitizedInput.length > 10) {
        return;
    }else if(sanitizedInput.length === 10){
      setBtndis("");
    }else{
      setBtndis("disabled");
    }
    setIdnational(sanitizedInput);
  };
  

  const handleLoginIdNational = () => {
    if (idnational.length > 5) {
      console.log(idnational);
      setLoading(true);
       axiosInstance
        .post("/idnational", { idnational: idnational })
        .then((response) => {
          const date = new Date();
          localStorage.setItem("idnationalChecked", true);
          localStorage.setItem("idnational", idnational);
          localStorage.setItem("registrationDate", date.getTime());
          navigate('/otp');
        })
        .catch((error) => {
          console.log(error);
          setIdnational("");
          SwitchAlert("", "يبدو أن رقم الهوية غير مسجل في سجلاتنا", "warning");
          setLoading(false);
        });
    } else {
      SwitchAlert("", "يبدو أنك نسيت إدخال رقم الهوية 😁", "info");
    }
  };

 
  return (
    <div
      className="container  d-flex justify-content-center align-items-center vh-100"
      style={{ direction: "rtl" }}
    >
      <div></div>
      <div className="">
        <div className="" style={{ width: "350px", position: "relative" }}>
          <div className="text-center">
            <img
              className="img-fluid p-3"
              src="/assets/logo_assembly.svg"
              width="200"
              alt="Logo"
            />
          </div>
          <div className="mb-3">
            <div>
              <div className="form-group">
                <label>رقم الهوية</label>
                <input
                value={idnational}
                onChange={handleIdNationalChange}
                  type="text"
                  className="form-control my-2 text-center fs-3 form-control-md"
                />
                <small  className="form-text text-muted">
                 تم إدخال عدد {idnational.length} من أصل 10 أرقام
                  
                </small>
              </div>
            </div>
          </div>
          <div className="mb-3">
            {ButtonLog(
              handleLoginIdNational,
              "تسجيل الدخول",
              "جاري إرسال كود التحقق ...",
              loading,
              btndis,
              
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
