import React, { useEffect, useState } from 'react';
import Avatar from '../components/layouts/Avatar';
import Votestatus from '../components/layouts/Votestatus';
import Questions from '../components/Home/Questions';
import MainPage from './MainPage';
import SwitchAlert from '../components/SwitchAlert';
import SwitchConfirm from '../components/SwitchConfirm';
import axiosInstance from '../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerPage from '../components/SpinnerPage';
import sweetConfirmOne from '../components/sweetConfirmOne';

const QuestionsPage = () => {


  const navigate = useNavigate();
  const { id } = useParams();
  const [formdata, setFormdata] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading,setLoading] = useState(true);
  // const [allSelectedOptions, setAllSelectedOptions] = useState({});
  const [allQusOpts, setAllQusOpts] = useState({}); 

  useEffect(() => {
    axiosInstance
      .get(`/form/${id}`)
      .then((response) => {
        console.log(response.data)
        setFormdata(response.data.formdata);
        setQuestions(response.data.questions);
        setOptions(response.data.options);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        if(error.response.data.error === 'access_error'){
          navigate(-1)
        }
        if(error.response.data.error === 'hasVotesInput'){
          navigate(`/answer/${id}`)
        }
         
        // console.error("There was an error fetching the voting forms!", error);
        setLoading(false);
      });
  }, [navigate]);


  const handleQusOptUpdate = (qusOpt) => {
    setAllQusOpts(prevState => ({
      ...prevState,
      ...qusOpt,
    }));
  };
 

  const [comp, setComp] = useState([]);

  // const questions = [
  //   { id: 1, title: 'هل توافق على البند رقم خمسة من النظام؟', count: 2, type: true },
  //   { id: 2, title: 'هل توافق على البند رقم ستة من النظام؟', count: 1, type: true },
  // ];

  const handleCompletion = (id) => {
    if (!comp.includes(id)) {
      setComp((prevComp) => [...prevComp, id]);
    }
  };

  // const handleCompletion = (questionId, selectedOptions) => {
  //   setAllSelectedOptions((prevState) => ({
  //     ...prevState,
  //     [questionId]: selectedOptions,
  //   }));
  // };
  

  const handleIncomplete = (id) => {
    if (comp.includes(id)) {
      setComp((prevComp) => prevComp.filter((completedId) => completedId !== id));
    }
  };

  const handleSubmit = ()=>{
    SwitchConfirm("","هل أنت متأكد من اعتماد التصويت","info", "الغاء","تأكيد",submitVotes)
  }
  const handlRedirect = ()=>{
     navigate(`/answer/${formdata.id}`);
  }

  const submitVotes = ()=>{
    axiosInstance.post('/storevote',{votes:allQusOpts,form_id:formdata.id})
          .then((response) => {
              console.log(response.data);
              sweetConfirmOne('','تم التصويت بنجاح','success','موافق',handlRedirect);
          })
          .catch((error) => {
              console.error("There was an error submitting the votes!", error);
          });
  }
  
  

  return (
    <MainPage>
      { loading ? <SpinnerPage/> :''}
      <div className="container" style={{ marginBottom: '100px' }}>
        <div className="col-12">
          <div className="card my-3">
            <div className="card-header align-items-center">
              <h4 className="mb-0 text-center">{formdata.name}</h4>
            </div>
          </div>
        </div>
        {questions.map((question) => (
          <Questions
            key={question.id}
            id={question.id}
            title={question.name}
            count={question.answer_count}
            onComplete={handleCompletion}
            onIncomplete={handleIncomplete}
            option_group_id={question.option_group_id}
            options={options}
            onQusOptUpdate={handleQusOptUpdate} // تمرير الدالة إلى Questions
          />
        ))}

        <button onClick={handleSubmit} className="btn btn-primary btn-md w-100 mt-3" type="button" disabled={comp.length < questions.length}>
          اعتماد التصويت
        </button>
      </div>
    </MainPage>
  );
};

export default QuestionsPage;
