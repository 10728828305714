import React, {useEffect, useState } from "react";
import "react-international-phone/style.css";
import axiosInstance from "../axiosInstance";
import Swal from "sweetalert2";
import style from "./Otp.module.css";
import SwitchAlert from "./SwitchAlert";
import ButtonLog from "./ButtonLog";
import { useNavigate } from "react-router-dom";



const Otp = () => {

    const [timer, setTimer] = useState(60); // بداية المؤقت بـ 60 ثانية
    const [canResend, setCanResend] = useState(false); // للتحكم في إظهار الزر
    const navigate = useNavigate();
    useEffect(() => {

        // axiosInstance.get("/login_test")
        // .then((response)=>{
        //   console.log(response);
        // })
        // .catch((error)=>{
        //   console.log(error);
        // });

        const registrationDate = localStorage.getItem('registrationDate');
        if (registrationDate) {
          const now = new Date().getTime();
          const timePassed = Math.floor((now - registrationDate) / 1000); // الفرق بالثواني
          
          if (timePassed < 60) {
            setTimer(60 - timePassed); // تحديث المؤقت
          } else {
            setCanResend(true); // السماح بإعادة إرسال الكود
          }
        }
    
        const interval = setInterval(() => {
          setTimer((prev) => {
            if (prev > 1) return prev - 1;
            setCanResend(true); // عند انتهاء المؤقت السماح بإعادة الإرسال
            return 0;
          });
        }, 1000);
    
        return () => clearInterval(interval); // تنظيف المؤقت عند إلغاء المكون
      }, []);

      const handleResendOtp = () => {
        // المنطق لإعادة إرسال الكود هنا
        axiosInstance.post('/idnational',{idnational:localStorage.getItem('idnational')})
        .then((response)=>{
          console.log(response.data);
          
        })
        .catch((error)=>{
          console.log(error);

        })
        const now = new Date().getTime();
        localStorage.setItem('registrationDate', now); // تحديث وقت الإرسال
        setTimer(60); // إعادة تعيين المؤقت إلى 60 ثانية
        setCanResend(false); // إخفاء الزر
      };
    
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });

  const handleChange = (e, field, nextField) => {
    const value = e.target.value;
    if (value.length === 1 && nextField) {
      document.getElementById(nextField).focus();
    }
    setOtp({ ...otp, [field]: value });
  };

  const handleCheckOtp = () => {
    const otpCode = otp['otp1'] + otp['otp2'] + otp['otp3'] + otp['otp4'];

    if (otpCode.length === 4) {
        axiosInstance.post("/otp_check", { otp_code: otpCode })
        .then((response) => {
          console.log(response.data)
            // تخزين البيانات الضرورية في Local Storage
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            console.log('Login successful and data stored in localStorage.');
            navigate('/home');
        })
        .catch((error) => {
            console.log(error.response.data);
            SwitchAlert('title', error.response.data.error, 'error');
        });
    } else {
        SwitchAlert('title', 'يجب إدخال جميع الأرقام الأربعة', 'warning');
    }
}

  return (
    <div
      className="container  d-flex justify-content-center align-items-center vh-100"
      style={{ direction: "rtl" }}
    >
      <div></div>
      <div className="">
        <div className="" style={{ width: "350px", position: "relative" }}>
          <div className="text-center">
            <img
              className="img-fluid p-3"
              src="/assets/logo_assembly.svg"
              width="200"
              alt="Logo"
            />
          </div>
          <div className="mb-3">
            <h5 className="my-3 text-secondary text-center" >أدخل كود التحقق المرسل إلى الواتساب </h5>
            <div className="mb-4">
              <div
                dir="ltr"
                className="auth-input-wrapper d-flex justify-content-center"
              >
                <input
                  type="tel"
                  className={` form-control auth-input h-px-50 text-center mx-sm-1 mt-2 m-2 ${style.boxinput}`}
                  maxLength="1"
                  id="otp1"
                  value={otp.otp1}
                  onChange={(e) => handleChange(e, "otp1", "otp2")}
                />
                <input
                  type="tel"
                  className={` form-control auth-input h-px-50 text-center mx-sm-1 mt-2 m-2 ${style.boxinput}`}
                  maxLength="1"
                  id="otp2"
                  value={otp.otp2}
                  onChange={(e) => handleChange(e, "otp2", "otp3")}
                />
                <input
                  type="tel"
                  className={` form-control auth-input h-px-50 text-center mx-sm-1 mt-2 m-2 ${style.boxinput}`}
                  maxLength="1"
                  id="otp3"
                  value={otp.otp3}
                  onChange={(e) => handleChange(e, "otp3", "otp4")}
                />
                <input
                  type="tel"
                  className={` form-control auth-input h-px-50 text-center mx-sm-1 mt-2 m-2 ${style.boxinput}`}
                  maxLength="1"
                  id="otp4"
                  value={otp.otp4}
                  onChange={(e) => handleChange(e, "otp4", null)}
                />
              </div>
              <input
                type="hidden"
                name="otp"
                id="otp"
                value={Object.values(otp).join("")}
              />

              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="mb-3">
            {ButtonLog(handleCheckOtp,'تسجيل الدخول','جاري التحقق',loading)}
            
            <button className="btn btn-md btn-block w-100 mt-4" onClick={handleResendOtp} disabled={!canResend}>
          {canResend ? "أعد إرسال الكود" : `يمكنك إعادة إرسال الكود بعد ${timer} ثانية`}
        </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
