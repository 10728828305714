import React, { useEffect, useState } from 'react'
import MainPage from './MainPage'
import Answers from '../components/Home/Answers'
import axiosInstance from '../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerPage from '../components/SpinnerPage';

const AnswerPage = () => {

  const navigate = useNavigate();
  const [questions,setQuestions] = useState([]);
  const [votingForm,setVotingForm] = useState([]);
  const {id} = useParams();
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`/result/${id}`)
      .then((response) => {
        console.log(response.data)
        setQuestions(response.data.questions)
        setVotingForm(response.data.votingForm)
        setLoading(false)
      })
      .catch((error) => {
        if(error.response.data.error === 'access_error'){
          navigate(-1)
        }
        if(error.response.data.error === 'NotVotesInput'){
          navigate(`/question/${id}`)
        }
        console.error("There was an error fetching the voting forms!", error);
        setLoading(false)
      });
  }, [navigate]);


  return (
    <>
    <MainPage>
      {loading ? <SpinnerPage/> : ''}
    <div className="container" style={{ marginBottom: "100px" }}>
          <div className="col-12">
            <div className="card my-3">
              <div className="card-header align-items-center">
                <h4 className="mb-0 text-center">{votingForm.name} </h4>
              </div>
            </div>
          </div>
          <Answers questions={questions}  />
        </div>
    </MainPage>
    </>
  )
}

export default AnswerPage