import React, { useEffect, useState } from "react";
import MainPage from "./MainPage";
import Avatar from "../components/layouts/Avatar";

const Profile = () => {
  const [inf, setInf] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const parsData = JSON.parse(userData);
      setInf(parsData);

      console.log(inf.name);
    }
  }, []);
  return (
    <MainPage>
      <div className="container">
        <div className="col-12">
          <div className="card mb-6">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center">
                <div className="avatar-wrapper ">
                  <div className="avatar avatar-xl flex-shrink-0 me-3">
                    <span className="avatar-initial rounded-circle bg-label-success">
                      <i className="bx bx-user fs-1"></i>
                    </span>
                  </div>
                </div>
              </div>
              <ul className="list-unstyled my-3 py-1">
  <li className="d-flex align-items-center mb-4">
    <i className="bx bx-user" />
    <span className="fw-medium mx-2">الاسم:</span>{" "}
    <span>{inf.name}</span>
  </li>
  <li className="d-flex align-items-center mb-4">
    <i className="bx bx-id-card" /> {/* أيقونة الهوية */}
    <span className="fw-medium mx-2">رقم الهوية:</span>{" "}
    <span>{inf.national_id}</span>
  </li>
  <li className="d-flex align-items-center mb-4">
    <i className="bx bx-mobile" /> {/* أيقونة الجوال */}
    <span className="fw-medium mx-2">رقم الجوال:</span>{" "}
    <span>{inf.mobile}</span>
  </li>
</ul>

            </div>
          </div>
        </div>
      </div>
    </MainPage>
  );
};

export default Profile;
