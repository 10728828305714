import React, { useEffect, useState } from "react";
import MainPage from "./MainPage";
import Avatar from "../components/layouts/Avatar";
import Lists from "../components/Home/Lists";
import Votenow from "../components/layouts/Votenow";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import SpinnerPage from "../components/SpinnerPage";

const HomePage = () => {
  const navigate = useNavigate();
  const [notVotedForms, setNotVotedForms] = useState([]);
  const [votedForms, setVotedForms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/votes")
      .then((response) => {
        console.log(response.data);
        setNotVotedForms(response.data.notVotedForms); // افترض أن الاستجابة تحتوي على notVotedForms
        setVotedForms(response.data.votedForms); // افترض أن الاستجابة تحتوي على votedForms
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the voting forms!", error);
        setLoading(false);
      });
  }, [navigate]);

  // if(votedForms.length === 0){
  //   return <h3 className="text-center fw-bold">Download Comments ...</h3>;
  // }

  return (
    <MainPage>
      <>
        {loading ? <SpinnerPage /> : ""}
        <div className="container">
          <div className="col-12">
            <div className="card my-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <Avatar />
                  </div>
                  <div className="col-6">
                    <Votenow voteCount={notVotedForms.length} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card my-3">
                <div className="card-header align-items-center">
                  <h6 className="card-action-title mb-0">الطلبات الحالية</h6>
                </div>
                <hr className="m-0"></hr>
                <div className="card-body p-1">
                  {notVotedForms.length !== 0 ? (
                    <ul className="list-unstyled mb-0">
                      {notVotedForms.map((item) => (
                        <Lists
                          key={item.id}
                          icon={"gavel"}
                          id={item.id}
                          name={item.name}
                          status={item.status}
                          type={"form"}
                          voteType={item.type}
                        />
                      ))}
                    </ul>
                  ) : (
                    <h5
                      className="text-center m-3"
                      style={{ color: "#d9dee3" }}
                    >
                      لا توجد طلبات للمشاركة
                    </h5>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card my-3">
                <div className="card-header align-items-center">
                  <h6 className="card-action-title mb-0">الطلبات المكتملة</h6>
                </div>
                <hr className="m-0"></hr>
                <div className="card-body p-1">
                  {votedForms.length !== 0 ? (
                    <ul className="list-unstyled mb-0">
                      {votedForms.map((item) => (
                        <Lists
                          key={item.id}
                          icon={"thumbs-up"}
                          id={item.id}
                          name={item.name}
                          status={item.status}
                          type={"answer"}
                        />
                      ))}
                    </ul>
                  ) : (
                    <h5
                      className="text-center m-3"
                      style={{ color: "#d9dee3" }}
                    >
                      لا توجد نتائج جديدة
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainPage>
  );
};

export default HomePage;
