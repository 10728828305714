import React from 'react'

const SpinnerPage = () => {
  return (
    <div
    style={{
      zIndex: "1000",
      background: "rgb(255 255 255 / 95%)",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
      <div className="text-center">
        <div className="d-flex justify-content-center my-3">
          <div className="sk-grid sk-primary">
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
          </div>
        </div>
        <div><h4>جاري تحميل البيانات... </h4></div>
      </div>
  </div>
  )
}

export default SpinnerPage