import React, { useEffect, useState } from 'react'
import SvgChart from '../Chart'

 
const Votenow = ({voteCount}) => {
  const [count,setCount] = useState();

  useEffect(() => {
    let currentCount = 0;
    
    if (voteCount === 0) {
      const intervalId = setInterval(() => {
        currentCount += 1; // زيادة تدريجية
        setCount(`${currentCount}%`);

        if (currentCount >= 100) {
          clearInterval(intervalId); // إيقاف التحديث عند الوصول إلى 100%
        }
      },20); // تغيير السرعة حسب الحاجة (50ms هنا تتيح زيادة بطيئة)

      return () => clearInterval(intervalId); // تنظيف المؤقت عند إلغاء التثبيت
    } else {
      setCount('0%');
    }
  }, [voteCount]);

  return (
    <div className='text-center' style={{borderRight:'1px solid #eceef1'}}>
    <span className="d-block fw-medium mb-1">مشاركات مطلوبة</span>
  <h4 className="card-title mb-1 text-dark">{voteCount}</h4>
  <small className="d-block mb-1">حالة المشاركة</small>
  <div className="d-flex align-items-center justify-content-center">
    <div className="progress w-50 me-2" style={{ height: 5 }}>
      <div
        className="progress-bar bg-success"
        style={{ width: count }}
        role="progressbar"
        aria-valuenow={78}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
    <small>{voteCount === 0 ? count : '0%'}</small>
  </div>
    </div>
  )
}

export default Votenow