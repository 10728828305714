import React from 'react'

const AnswerCheck = ({text}) => {
  return (
    <div className="d-flex align-items-center">
    <span className="badge bg-label-primary rounded-pill p-1_5 me-3">
      <i className="bx bx-book-open bx-xs" />
    </span>
      <div className="d-flex flex-column">
        <h6 className="mb-0" 
        style={{lineHeight:'1.4',fontSize:'0.8rem'}}
        >{text}</h6>
      </div>
    </div>
  )
}

export default AnswerCheck