import React, { useState } from "react";
import "./Nav.css";
import { useNavigate } from "react-router-dom";
 import SwitchConfirm from "../SwitchConfirm";

const BottomNav = () => {
  const [active, setActive] = useState("home");
  const navigate = useNavigate();


  const handleNavigation = (route, name) => {
    setActive(name);
    navigate(route)
  };
  const confirmLogout = ()=>{
    localStorage.clear();
    navigate('/login');
    }
  // tit = "هل أنت متأكد من اعتماد التصويت", 
  // msg = "", 
  // type = "info", 
  // cancel = "الغاء", 
  // confirm = "تأكيد", 
  // onConfirm = null 
  const logout = () => {
    SwitchConfirm(
      "","هل أنت متأكد من تسجيل الخروج من النظام","question","إلغاء","نعم",confirmLogout
    )


}

  return (
    <div className="bottom-nav" style={{ zIndex: '1000' }}>
      <div
        className={`nav-item ${active === "home" ? "active" : ""}`}
        onClick={() => handleNavigation("/home", "home")}
        onMouseEnter={() => setActive("home")}
      >
        <i className="icon bx bx-home" />
        <span>الرئيسية</span>
      </div>
      <div
        className={`nav-item ${active === "profile" ? "active" : ""}`}
        onClick={() => handleNavigation("/profile", "profile")}
        onMouseEnter={() => setActive("profile")}
      >
        <i className="icon bx bx-user" />
        <span>حسابي</span>
      </div>
      <div
        className={`nav-item ${active === "logout" ? "active" : ""}`}
        onClick={logout}
        onMouseEnter={() => setActive("logout")}
      >
        <i className="icon bx bx-log-out" />
        <span>الخروج</span>
      </div>
      <div
        className={`nav-item ${active === "back" ? "active" : ""}`}
        onClick={()=>{navigate(-1)}}
        onMouseEnter={() => setActive("back")}
      >
        <i className="icon bx bx-arrow-back" />
        <span>الخلف</span>
      </div>
    </div>
  );
};

export default BottomNav;
