import React, { useEffect, useState } from "react";
import Switches from "../layouts/Switches";
import Options from "../layouts/Options";

const Questions = ({ id, title, count, onComplete, onIncomplete,option_group_id,options, onQusOptUpdate}) => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [previousOptions, setPreviousOptions] = useState([]); // To store previous options for comparison


  const filteredOptions = options.filter(option => option.option_group_id === option_group_id);

  const handleSwitchChange = (optionId, checked, questionId) => {
    if (checked) {
      if (selectedCount < count) {
        setSelectedCount(selectedCount + 1);
        setSelectedOptions([...selectedOptions, optionId]);
        
      }
    } else {
      setSelectedCount(selectedCount - 1);
      setSelectedOptions(selectedOptions.filter(id => id !== optionId));
    }
  };

  useEffect(() => {
    if (selectedCount === count) {
      onComplete(id); // استدعاء onComplete عند اكتمال السؤال
    } else {
      onIncomplete(id); // استدعاء onIncomplete عند إلغاء تحديد الخيار وجعل السؤال غير مكتمل
    }

    // التحقق من أن `selectedOptions` قد تغيرت فعليًا قبل تحديث `onQusOptUpdate`
    if (JSON.stringify(selectedOptions) !== JSON.stringify(previousOptions)) {
      onQusOptUpdate({ [id]: selectedOptions});
      setPreviousOptions(selectedOptions); // Update previousOptions for next comparison
    }

    // console.log(qusOpt); // طباعة qusOpt للتأكد من التخزين
  }, [selectedCount, count, id, onComplete, onIncomplete,selectedOptions]);

  return (
    <div className="col-12 mb-6 mb-lg-0 my-3">
      <div className="card">
        <div className="">
          <table className="table table-sm table-border-top-0">
            <thead>
              <tr>
                <th colSpan="2" className="py-2" style={{ fontSize: '0.9rem', letterSpacing: '0' }}>
                  {title}
                  <div><small>يجب الإجابة على هذا السؤال لعدد {count} اختيارات</small></div>
                </th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              {filteredOptions.map((option) => (
                <tr key={option.id}>
                  <td>
                    <Options text={option.name} />
                  </td>
                  <td style={{textAlign:'left'}}>
                    <Switches
                      onChange={(checked) => handleSwitchChange(option.id, checked)}
                      disabled={selectedCount >= count && !selectedOptions.includes(option.id)}
                      checked={selectedOptions.includes(option.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Questions;
