import React from "react";
import Nav from "../components/Home/Nav";
import Appbar from "../components/Appbar"
import "../components/Appbar.css";

const MainPage = ({children}) => {
  return (
    <>
      <div className="mainPage" style={{marginBottom:'100px'}}>
        <Appbar/>
         <Nav />
        {children}
      </div>
    </>
  );
};

export default MainPage;
