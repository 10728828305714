import Swal from "sweetalert2";
import SwitchAlert from "./SwitchAlert";

const SwitchConfirm = (
  tit = "هل أنت متأكد من اعتماد التصويت", 
  msg = "", 
  type = "info", 
  cancel = "الغاء", 
  confirm = "تأكيد", 
  onConfirm = null 
) => {
  const alert = Swal.fire({
    title: tit, // عرض العنوان إذا كان متوفراً
    text: msg,
    icon: type,
    iconHtml: "؟",
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonColor: "#c8aa54",
    confirmButtonColor: "#317750", // لون زر التأكيد
    cancelButtonText: cancel,
    confirmButtonText: confirm,
  }).then((result) => {
    if (result.isConfirmed && onConfirm) {
        onConfirm();
    }
  });

  return alert;
};

export default SwitchConfirm;
