import React from 'react'

const Appbar = () => {
  return (
    <>
            <div className="topapp">
          <div className="appbar">
            <h3 className="text-center mb-2">جمعية المساهمين</h3>
            <h3 className="text-center" style={{fontSize:'0.8rem'}}>بشركة مكيون مطورون عمرانيون</h3>
          </div>
        </div>
    </>
  )
}

export default Appbar